import { useTranslation } from '../../lib/i18n';

export const Discount = ({ pricing, className = '' }) => {
  const price = pricing.priceRange.start.gross.amount;
  const { t } = useTranslation();
  const undiscountedPrice = pricing.priceRangeUndiscounted.start.gross.amount;

  const discount = Math.round(100 - (100.0 / undiscountedPrice) * price);

  return (
    <div
      className={`h-[74px] w-[74px] rounded-full bg-actionRed text-center text-2xl font-bold leading-[74px] text-white ${className}`}
      aria-label={t('discount')}
    >
      {discount}%
    </div>
  );
};
