import rosetta from 'rosetta';

import { useRouter } from 'next/router';
import localeDeChCommon from '../generated/i18n-de-CH.json';
import localeFrChCommon from '../generated/i18n-fr-CH.json';

import deNavigation from '../generated/navigation-de-CH.json';
import frNavigation from '../generated/navigation-fr-CH.json';

import deFilters from '../generated/filters-de-CH.json';
import frFilters from '../generated/filters-fr-CH.json';

export const SUPPORTED_LOCALES = ['de', 'fr'];

export const DEFAULT_LOCALE = 'de';

export const LOCALE_NAMES = {
  de: 'DE',
  fr: 'FR',
};

export const LOCALE_NAMES_LONG = {
  de: 'Deutsch',
  fr: 'Français',
};

const translations = rosetta({
  de: {
    common: localeDeChCommon,
  },
  fr: {
    common: localeFrChCommon,
  },
});

export const sanitizeLocale = (locale) => {
  if (SUPPORTED_LOCALES.includes(locale)) {
    return locale;
  } else if (locale.indexOf('-') > 0) {
    return sanitizeLocale(locale.split('-')[0]);
  }

  return DEFAULT_LOCALE;
};

const empty = (text) => !text || text === '';

const titleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

const getLocaleFromRequest = (request) => {
  return request.cookies['NEXT_LOCALE'] || DEFAULT_LOCALE;
};

export const useTranslatedNavigation = () => {
  const { locale } = useRouter();
  return locale === 'fr' ? frNavigation : deNavigation;
};

export const useTranslatedFilters = () => {
  const { locale } = useRouter();
  return (locale === 'fr' ? frFilters : deFilters).filters;
};

export const getTranslatedFilters = (request) => {
  const locale = getLocaleFromRequest(request);
  return locale === 'fr' ? frFilters : deFilters;
};

export const useTranslation = (section) => {
  const { locale } = useRouter();

  return {
    t: (key, data = undefined) => translate({ section, locale, key, data }),
    attrT: (obj, attrName) => {
      if (!obj) {
        return '';
      }
      const key =
        locale === DEFAULT_LOCALE ? attrName : `${attrName}_${locale}`;
      return obj[key] ? obj[key] : obj[attrName];
    },
    valT: (obj, valName) => {
      if (!obj) {
        return '';
      }
      const key = locale === DEFAULT_LOCALE ? 'value' : `value_${locale}`;
      return obj[valName][key] ? obj[valName][key] : obj[valName].value;
    },
    locale,
  };
};

export const getTranslationFromRequest = (request, section) => {
  const locale = getLocaleFromRequest(request);
  return (key, data = undefined) => translate({ section, locale, key, data });
};

export const getTranslation = (locale, section) => {
  return (key, data = undefined) => translate({ section, locale, key, data });
};

const cleanKey = (key) => key.replaceAll('.', '').replace(/<[^>]*>/g, '');

const purifyKey = (key, section, sectionIndex) => {
  if (key.indexOf(':') > -1) {
    key = key.replace(':', '.');
  } else if (Array.isArray(section)) {
    key = section[sectionIndex] + '.' + key;
  } else {
    key = (section || 'common') + '.' + key;
  }
  return key;
};

export const translate = ({ key, locale, section, data, sectionIndex = 0 }) => {
  let _key = purifyKey(key, section, sectionIndex);

  let result = translations.t(_key, data, locale);
  if (empty(result)) {
    result = translations.t(purifyKey(cleanKey(key)), data, locale);

    if (empty(result)) {
      result = translations.t(_key, data, DEFAULT_LOCALE);
      if (empty(result)) {
        if (Array.isArray(section) && section.length > sectionIndex + 1) {
          return translate({
            key,
            locale,
            section,
            data,
            sectionIndex: sectionIndex + 1,
          });
        }
      }

      // fallback to processed key (e.g 'hello.nice-world' => 'Nice World' )
      return titleCase((_key.split('.').pop() || _key).replace(/-/g, ' '));
    }
  }
  return result;
};

translations.locale(DEFAULT_LOCALE);
